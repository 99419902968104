// src/components/Footer.js
import React from "react";
import { useLegal } from "../hooks/use-legal";
import { renderContent } from "../components/renderContent";

const Footer = () => {
  const legal = useLegal();

  return (
    <>
      <dialog id="my_modal_2" class="modal text-neutral-900">
        <div class="modal-box">
          {legal.privacyPolicy ? (
            <div>
              <h1 className="text-3xl font-bold mb-4">{legal.privacyPolicy.title}</h1>
              {legal.privacyPolicy.sections.map((section) => (
                <div key={section.id} className="mb-6">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-3">{section.title}</h2>
                  {renderContent(section.content)}
                </div>
              ))}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <form method="dialog" class="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      <dialog id="my_modal_3" class="modal text-neutral-900">
        <div class="modal-box">
          {legal.termsAndConditions ? (
            <div>
              <h1 className="text-3xl font-bold mb-4">{legal.termsAndConditions.title}</h1>
              {legal.termsAndConditions.sections.map((section) => (
                <div key={section.id} className="mb-6">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-3">{section.title}</h2>
                  {renderContent(section.content)}
                </div>
              ))}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <form method="dialog" class="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      <footer className="text-neutral-50 bg-neutral-800 py-8">
        <div className="max-w-7xl mx-auto px-5 text-center">
          <p className="mb-4">
            &copy; 2024 Model<b>buk</b>. All rights reserved.
          </p>
          <nav className="space-x-4">
            <button
              onClick={() => {
                const modal = document.getElementById("my_modal_2");
                if (modal instanceof HTMLDialogElement) {
                  modal.showModal();
                }
              }}
              className="hover:text-gray-400"
            >
              Privacy Policy
            </button>
            <button onClick={() => {
              const modal = document.getElementById("my_modal_3");
              if (modal instanceof HTMLDialogElement) {
                modal.showModal();
              }
            }} className="hover:text-gray-400">
              Terms of Service
            </button>
            <a
              href="mailto:support@modelbuk.com"
              className="hover:text-gray-400"
            >
              Contact
            </a>
          </nav>
        </div>
      </footer>
    </>
  );
};

export default Footer;
