// src/components/Header.js
import React, { useEffect } from "react";

const Header = ({ toggleSideNav }) => {
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector("header");
      if (window.scrollY > 50) {
        header.classList.add("bg-neutral-900", "fixed");
      } else {
        header.classList.remove("bg-neutral-900", "fixed");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="py-8 px-5 w-full bg-neutral-900">
      <div className="mx-auto container flex justify-between items-center">
        <h1 className="text-2xl font-bold uppercase">
          <button>
            MODEL<b>BUK</b>
          </button>
        </h1>
        <nav className="space-x-4 text-xl font-light hidden md:block">
          <a href="#features" className="hover:text-primaryColor">
            Features
          </a>
          <a href="mailto:support@modelbuk.com" className=" hover:text-primaryColor">
            Contact
          </a>
        </nav>
        <button onClick={toggleSideNav} className="md:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
      </div>
    </header>
  );
};

export default Header;
