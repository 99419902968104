

export const renderContent = (content) => {
    return content.map((item, index) => {
      if (item.type === 'paragraph') {
        return <p key={index} className="mb-2 text-gray-700">{item.text}</p>;
      } else if (item.type === 'list') {
        return (
          <ul key={index} className="list-disc pl-6 mb-2">
            {item.items.map((listItem, idx) => (
              <li key={idx} className="text-gray-700">{listItem}</li>
            ))}
          </ul>
        );
      } else if (item.type === 'disclaimer') {
        return <p key={index} className="mb-2 text-red-500 font-semibold">{item.text}</p>;
      }
      return null;
    });
  };