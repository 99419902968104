// import logo from './logo.svg';
import "./App.css";
import heroImg from "./heroImg.png";
import featuresImg from "./featuresImg.png";
import imgDownload from "./imgDownload.png";
import appStore from "./appStore.png";
import googlePlay from "./googlePlay.png";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import { useSidenav } from "./hooks/use-sidenav";
import Sidenav from "./layout/Sidenav";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function App() {
  const { sideNavOpen, toggleSideNav } = useSidenav();

  return (
    <>
      <Sidenav sideNavOpen={sideNavOpen} toggleSideNav={toggleSideNav} />
      <div className="flex min-h-[90vh] bg-neutral-900 text-neutral-50 flex-col">
        <Header toggleSideNav={toggleSideNav} />
        <div className="flex-grow">
          <section className="w-full px-5">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="mx-auto container min-h-screen items-center flex"
            >
              <div className="grid md:grid-cols-2 items-center gap-28 md:gap-0">
                <div className="flex flex-col gap-4 text-center md:text-left">
                  <h2 className="text-4xl font-black">
                    Link, connect and chat
                  </h2>
                  <h3 className="text-4xl">with models near you</h3>
                  <p className="pb-6">
                    Welcome to the most exciting way to instantly connect with
                    models in your neighborhood.
                  </p>
                  <div className="flex gap-4 md:justify-start justify-center">
                    <a href="#download" className="btn bg-primaryColor px-8 py-2 rounded-full">
                      Download Here
                    </a>
                  </div>
                </div>
                <div>
                  <img src={heroImg} alt="hero" className="w-5/4 mx-auto" />
                </div>
              </div>
            </motion.div>
          </section>

          <section className="bg-neutral-800 py-16" id="features">
            <div className="mx-auto container grid grid-cols-1 md:grid-cols-2 gap-28 md:gap-0 items-center">
              <div className="flex flex-col justify-center text-center md:text-left gap-4">
                <div>
                  <p className="text-5xl font-black">Meet models from</p>
                  <p className="text-5xl font-black text-primaryColor">
                    anywhere, anytime
                  </p>
                </div>
                <p className="text-xl">
                  Now you can chat and connect with one of our beautiful models.
                </p>
                <ul className="list-none list-inside text-xl gap-4 flex flex-col justify-center md:items-start items-center">
                  <li className="flex gap-4">
                    <div className="h-6 w-6 bg-primaryColor rounded-full text-center">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    Chat with models
                  </li>
                  <li className="flex gap-4">
                    <div className="h-6 w-6 bg-primaryColor rounded-full text-center">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    Connect with models
                  </li>
                  <li className="flex gap-4">
                    <div className="h-6 w-6 bg-primaryColor rounded-full text-center">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    Meet models
                  </li>
                </ul>
              </div>
              <div>
                <img
                  src={featuresImg}
                  alt="features"
                  className="w-3/4 mx-auto"
                />
              </div>
            </div>
          </section>

          <section className="py-16 px-5" id="download">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="mx-auto container items-center text-center md:text-right grid grid-cols-1 md:grid-cols-2 gap-4"
            >
              <img src={imgDownload} alt="download" className="w-4/5 mx-auto" />
              <div className="flex flex-col gap-8">
                <div>
                  <span className="text-primaryColor">DOWNLOAD THE APP</span>
                  <div>
                    <h2 className="text-4xl font-black">
                      Access to the top local models
                    </h2>
                    <h2 className="text-4xl font-black">
                      and get inspired by the best portfolios
                    </h2>
                  </div>
                </div>
                <p className="text-xl">
                  Discover an exclusive collection of the finest local models
                  conveniently gathered in one place.
                </p>
                <div className="flex justify-center md:justify-end gap-4">
                  <img src={appStore} alt="app store" className="w-1/4" />
                  <img src={googlePlay} alt="google play" className="w-1/4" />
                </div>
              </div>
            </motion.div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
