import React from "react";
import "./styles.css";

export default function Sidenav({ sideNavOpen, toggleSideNav }) {
  return (
    <div className="sidebar-menu-wrapper text-gray-50">
      <div
        className={`mobile_sidebar_menu bg-gray-800 font-primaryFont ${
          sideNavOpen && "active"
        }`}
      >
        <button type="button" onClick={toggleSideNav} className="close_btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="about_content mb-16">
          <div className="brand_logo mb-4 flex w-2/3">
            <button className="text-3xl">
              MODEL<b>BUK</b>
            </button>
          </div>
        </div>

        <div className="menu_list mb_60 clearfix">
          <ul className="ul_li_block clearfix">
            <li>
              <a href="#features" onClick={toggleSideNav}>
                Features
              </a>
            </li>
            <li>
              <a href="#download" onClick={toggleSideNav}>
                Download
              </a>
            </li>
            <li>
              <a href="mailto:support@modelbuk.com" onClick={toggleSideNav}>
                Contact
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-8" />
        <p>
          &copy; 2024 Model<b>buk</b>. All rights reserved
        </p>
      </div>
      <div
        role="button"
        tabIndex="0"
        onClick={toggleSideNav}
        className={`overlay bg-slate-800/45 ${sideNavOpen && "active"}`}
      ></div>
    </div>
  );
}