import { useEffect, useState } from "react";


export function useLegal() {
    const [termsAndConditions, setTermsAndConditions] = useState();
    const [privacyPolicy, setPrivacyPolicy] = useState();

    useEffect(() => {
        fetch("/legal.json")
            .then((response) => response.json())
            .then((data) => {
                setTermsAndConditions(data.content.termsAndConditions);
                setPrivacyPolicy(data.content.privacyPolicy);
            });
    }, []);

    return { termsAndConditions, privacyPolicy };
}